import Vue from 'vue/dist/vue.esm.js'

import BtRankingChart from '../../components/BtRankingChart/BtRankingChart.vue'
import BtRaisedChart from '../../components/BtRaisedChart/BtRaisedChart.vue'
import BtBackersChart from '../../components/BtBackersChart/BtBackersChart.vue'

const TAB_TARGETS = {
  ranking: 'ranking',
  raised: 'raised',
  backers: 'backers'
}

const $el = document.querySelector('[data-behavior="projects-edit"]')

if ($el) {
  new Vue({
    el: $el,

    components: {
      'bt-ranking-chart': BtRankingChart,
      'bt-raised-chart': BtRaisedChart,
      'bt-backers-chart': BtBackersChart
    },

    data() {
      return {
        tabTargets: TAB_TARGETS,
        tabTarget: TAB_TARGETS[getUrlParameter('target')] || TAB_TARGETS.backers
      }
    },

    created() {
      this.project = window.__sharedData.project
    }
  })
}

function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  var results = regex.exec(location.search)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}
