export default {
  methods: {
    accumulate(values) {
      return values.reduce((array, value, index) => {
        if (index === 0) {
          array.push(value)
        } else {
          array.push(value - values[index - 1])
        }

        return array
      }, [])
    }
  }
}
