import Vue from 'vue/dist/vue.esm.js'

// Components
import BtRankingChart from '../components/BtRankingChart/BtRankingChart.vue'
import BtTaiwanRankingChart from '../components/BtTaiwanRankingChart/BtTaiwanRankingChart.vue'
import BtRaisedChart from '../components/BtRaisedChart/BtRaisedChart.vue'
import BtBackersChart from '../components/BtBackersChart/BtBackersChart.vue'
import BtModal from '../components/BtModal/BtModal.vue'

// Directives
import VTooltip from 'v-tooltip'
import VAutoselect from '../directives/v-autoselect.js'
import VClipboard from '../directives/v-clipboard.js'

import '../application/projects/edit'
import '../application/pages/popular'

Vue.use(VTooltip)
Vue.use(VAutoselect)
Vue.use(VClipboard)

const $el = document.querySelector('[data-behavior="projects-show"]')

if ($el) {
  new Vue({
    el: $el,

    components: {
      'bt-taiwan-ranking-chart': BtTaiwanRankingChart,
      'bt-ranking-chart': BtRankingChart,
      'bt-raised-chart': BtRaisedChart,
      'bt-backers-chart': BtBackersChart,
      'bt-modal': BtModal
    },

    data() {
      return {
        project: null,
        isSharing: false,
        iframeTarget: 'backers'
      }
    },

    created() {
      this.project = window.__sharedData.project
    },

    computed: {
      iframeCode() {
        return `<iframe width="100%" height="100%" src="https://backtail.tw/embed/${this.project.cid}?target=${this.iframeTarget}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" style="height: 390px; width: 100%; position: relative; margin: 0px auto; display: inline-block;"></iframe>`
      }
    },

    methods: {
      onSharing() {
        this.isSharing = !this.isSharing
      },

      onSharingModalClosing() {
        this.isSharing = false
      },

      onCopy() {
        return
      }
    }
  })
}

// The above code uses Vue without the compiler, which means you cannot
// use Vue to target elements in your existing html templates. You would
// need to always use single file components.
// To be able to target elements in your existing html/erb templates,
// comment out the above code and uncomment the below
// Add <%= javascript_pack_tag 'hello_vue' %> to your layout
// Then add this markup to your html template:
//
// <div id='hello'>
//   {{message}}
//   <app></app>
// </div>


// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })
//
//
//
// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//
// import TurbolinksAdapter from 'vue-turbolinks'
// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// Vue.use(TurbolinksAdapter)
//
// document.addEventListener('turbolinks:load', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: () => {
//       return {
//         message: "Can you say hello?"
//       }
//     },
//     components: { App }
//   })
// })
