import Vue from 'vue/dist/vue.esm.js'

const VAutoselect = {
  install(Vue, options) {
    function onClick(event) {
      event.target.setSelectionRange(0, event.target.value.length)
    }

    Vue.directive('autoselect', {
      bind(el, binding, vnode, oldVnode) {
        el.addEventListener('click', onClick)
      },

      unbind(el, binding) {
        el.removeEventListener('click', onClick)
      }
    })
  }
}

export default VAutoselect
