import Vue from 'vue/dist/vue.esm.js'
import ClipboardJS from 'clipboard'

const VClipboard = {
  install(Vue, options) {
    let clipboard

    Vue.directive('clipboard', {
      bind(el, binding, vnode, oldVnode) {
        clipboard = new ClipboardJS(el, {
          text() {
            return binding.value
          }
        })
      },

      unbind(el, binding) {
        clipboard.destroy()
      }
    })
  }
}

export default VClipboard
