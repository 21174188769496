<template>
  <div class="chart">
    <canvas :ref="'raised-canvas'"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js'
import chartDurationDates from '../../mixins/chart-duration-dates'
import predict from '../../mixins/predict'
import accumulate from '../../mixins/accumulate'

export default {
  name: 'bt-raised-chart',

  mixins: [chartDurationDates, predict, accumulate],

  props: {
    project: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  computed: {
    predictRaised() {
      return this.predict('raisedAccumulator', this.durationDateList)
    },

    raisedAccumulator() {
      return this.durationDateList.map((date) => {
        return this.project.raisedAccumulator[date] || NaN
      })
    },

    rewindRaisedAccumulation() {
      const rewindRaisedAccumulation = this.accumulate(Object.values(this.project.raisedAccumulator))
      let index = 0
      return this.durationDateList.map((date) => {
        if (this.project.raisedAccumulator[date] !== undefined) {
          const result = rewindRaisedAccumulation[index]
          index++
          return result
        } else {
          return NaN
        }
      })
    }
  },

  mounted() {
    this.raisedChart = new Chart(this.$refs['raised-canvas'], {
      type: 'bar',
      data: {
        labels: this.durationDateList,
        datasets: this.getRaisedChartDataset()
      },
      options: {
        maintainAspectRatio: false,
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            afterLabel: (tooltipItem, data) => {

              return `原貨幣 (${this.project.uncomputedRaisedAccumulator[tooltipItem.label]})`
            }
          }
        },
        scales: {
          xAxes: [
            {
              barPercentage: 0.4,
              gridLines: {
                drawOnChartArea: false
              }
            }
          ],
          yAxes: [
            {
              type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
              display: true,
              position: 'right',
              id: 'y-axis-1',
              precision: 0,
              ticks: {
                userCallback: (val) => {
                  const valK = Number(val / 1000)
                    .toString()
                    .replace(
                      /^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/,
                      (all, pre, groupOf3Digital) => pre + groupOf3Digital.replace(/\d{3}/g, ',$&')
                    )
                  return valK + 'k'
                },
                fontColor: '#000045',
                stepSize: 10000000
              },
              scaleLabel: {
                display: true,
                labelString: '累積集資金額',
                fontColor: '#000045',
                fontFamily: 'Noto Sans TC',
                fontSize: 13
              }
            },
            {
              type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
              display: true,
              position: 'left',
              id: 'y-axis-2',

              // grid line settings
              gridLines: {
                drawOnChartArea: false // only want the grid lines for one axis to show up
              },
              ticks: {
                userCallback: (val) => {
                  const valK = Number(val / 1000)
                    .toString()
                    .replace(
                      /^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/,
                      (all, pre, groupOf3Digital) => pre + groupOf3Digital.replace(/\d{3}/g, ',$&')
                    )
                  return valK + 'k'
                },
                fontColor: 'rgb(94, 192, 248)',
                stepSize: 1000000
              },
              scaleLabel: {
                display: true,
                labelString: '每日集資金額',
                fontColor: 'rgb(94, 192, 248)',
                fontFamily: 'Noto Sans TC',
                fontSize: 13
              }
            }
          ]
        }
      }
    })
  },

  methods: {
    getRaisedChartDataset() {
      return [
        {
          label: '累積集資金額',
          type: 'line',
          data: this.raisedAccumulator,
          backgroundColor: '#000045',
          fill: false,
          yAxisID: 'y-axis-1',
          borderWidth: 0
        },
        {
          label: `預計累積集資金額(${this.predictDisplay('raisedAccumulator')})`,
          type: 'line',
          data: Object.values(this.predictRaised),
          backgroundColor: 'rgba(0, 0, 69, 0.3)',
          borderDash: [2, 2],
          fill: false,
          borderWidth: 0
        },
        {
          label: '每日集資金額',
          data: this.rewindRaisedAccumulation,
          backgroundColor: 'rgb(94, 192, 248)',
          fill: true,
          yAxisID: 'y-axis-2',
          borderWidth: 0,
          order: 1
        }
      ]
    }
  }
}
</script>

<style scoped>
.chart {
  height: 320px;
}
</style>
