import Vue from 'vue/dist/vue.esm.js'

const $el = document.querySelector('[data-behavior="pages-popular"]')

if ($el) {
  new Vue({
    el: $el,

    data() {
      return {
        isLocal: true,
        localProjects: [],
        allProjects: []
      }
    },

    computed: {
      projects() {
        if (this.isLocal) {
          return this.localProjects
        } else {
          return this.allProjects
        }
      }
    },

    mounted() {
      this.localProjects = JSON.parse(this.$el.dataset.localProjects)
      this.allProjects = JSON.parse(this.$el.dataset.allProjects)
    },

    methods: {
      filterProjectsBy(type) {
        this.isLocal = type === 'local'
      }
    }
  })
}
