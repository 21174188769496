<template>
  <div class="position-fixed top-0 z-900">
    <transition name="fade-overlay">
      <div v-if="active" class="overlay" @click="onClose"></div>
    </transition>

    <transition name="fade-modal">
      <div
        v-if="active"
        class="modal"
        :class="{ center }"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'bt-modal',

  props: {
    active: {
      type: Boolean,
      default: false
    },

    center: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
$overlay-enter-duration: 0.3s;
$overlay-leave-duration: 0.25s;
$modal-enter-duration: 0.25s;
$modal-leave-duration: 0.3s;

.overlay {
  position: fixed;
  z-index: 900;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparentize(#000000, 0.15);
}

.modal {
  position: fixed;
  z-index: 900;
  top: 0;

  &.center {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

/* fade-overlay */

.fade-overlay-enter,
.fade-overlay-leave-to {
  opacity: 0;
}

.fade-overlay-enter-to,
.fade-overlay-leave {
  opacity: 1;
}

.fade-overlay-enter-active {
  transition: opacity $overlay-enter-duration linear;
}

.fade-overlay-leave-active {
  transition: opacity $overlay-leave-duration linear $modal-leave-duration;
}

/* fade-modal */

.fade-modal-enter,
.fade-modal-leave-to {
  opacity: 0;
}

.fade-modal-enter-to,
.fade-modal-leave {
  opacity: 1;
}

.fade-modal-enter-active {
  transition: opacity $modal-enter-duration linear $overlay-enter-duration;
}

.fade-modal-leave-active {
  transition: opacity $modal-leave-duration linear;
}
</style>
