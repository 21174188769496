<template>
  <div class="chart">
    <canvas :ref="'ranking-canvas'"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js'

export default {
  name: 'bt-taiwan-ranking-chart',

  props: {
    project: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  watch: {
    project() {
      this.rankingChart.data.labels = this.getRankingChartLabels()
      this.rankingChart.data.datasets = this.getRankingChartDataset()
      this.rankingChart.update()
    }
  },

  mounted() {
    this.rankingChart = new Chart(this.$refs['ranking-canvas'], {
      type: 'line',
      data: {
        labels: this.getRankingChartLabels(),
        datasets: this.getRankingChartDataset()
      },
      options: {
        legend: {
          display: false
        },
        maintainAspectRatio: false,
        tooltips: {
          mode: 'index',
          intersect: false
        },
        scales: {
          yAxes: [
            {
              ticks: {
                reverse: true,
                stepSize: 5,
                precision: 0,
                fontColor: '#000045'
              },
              scaleLabel: {
                display: true,
                labelString: '排行榜',
                fontColor: '#000045',
                fontFamily: 'Noto Sans TC',
                fontSize: 13
              }
            }
          ]
        }
      }
    })
  },

  methods: {
    getRankingChartLabels() {
      return Object.keys(this.project.taiwanBackersRankingRecord)
    },

    getRankingChartDataset() {
      return [
        {
          data: Object.values(this.project.taiwanBackersRankingRecord),
          borderColor: '#000045',
          fill: false,
          lineTension: 0
        }
      ]
    }
  }
}
</script>

<style scoped>
.chart {
  height: 320px;
}
</style>
