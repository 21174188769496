import { format, addDays, differenceInDays } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

export default {
  computed: {
    // 偶爾會碰到 raisedAccumulator 有資料的時間在起始時間之外的，這狀況非常罕見，但是一旦遇上了
    // 系統原本會無法處理而拋出例外。
    //
    // 取得時間的邏輯：判斷開始時間或是 raisedAccumulator 有資料的時間，其中較早的日期，如果無法判斷則
    // fallback 到其中一個有日期的時間。
    startDate() {
      const dates = Object.keys(this.project.raisedAccumulator)
      const firstDate = dates[0]
      const startedAt = new Date(this.project.startedAt)
      const firstZonedDate = utcToZonedTime(firstDate || new Date(), 'Asia/Taipei')

      if (!firstDate && this.project.startedAt) {
        return startedAt
      }

      if (this.project.startedAt && firstDate) {
        return Math.min(startedAt, firstZonedDate)
      }

      if (!this.project.startedAt && firstDate) {
        return firstZonedDate
      }

      return new Date()
    },

    endDate() {
      return this.project.endedAt ? new Date(this.project.endedAt) : new Date()
    },

    durationDays() {
      const diff = differenceInDays(this.endDate, this.startDate)
      return diff >= 0 ? diff : 0
    },

    durationDateList() {
      const list = [...Array(this.durationDays + 1).keys()].map((i) => format(addDays(this.startDate, i), 'yyyy-MM-dd'))

      return list
    }
  }
}
