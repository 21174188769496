export default {
  methods: {
    /**
     * 平均成長率 - 每一天比前一天成長了多少的平均
     * @param {String} key - 計算成長率的物件的 key
     * @returns {Number} 成長率
     */
    growthRate(key) {
      // 第一天都不看，資料不完全可能誤差很大
      const offset = 1
      const predict = Object.assign({}, this.project[key])

      const growth = Object.values(predict).reduce((acc, curr, index, array) => {
        if (index < offset) {
          acc.push(1)
          return acc
        }

        acc.push(this.getPercentageChange(array[index - 1], curr))
        return acc
      }, [])

      const avgRate = growth.reduce((acc, curr) => acc * curr, 1) ** (1 / (growth.length - 1 - offset))

      return ((avgRate ** (1 / 2)) ** (1 / 2)) ** (1 / 2)
    },

    /**
     * 資料是否足夠計算避免成長幅度過大
     * @param {String} key - stats 內要計算的 key name
     * @returns {Boolean}
     */
    canPredict(key) {
      return Object.values(this.project[key]).length > 4
    },

    /**
     * 預測至結束日期的資料
     * @param {String} key - stats 內要計算的 key name
     * @returns {Object}
     */
    predict(key, durationDateList) {
      const statsData = Object.values(this.project[key])

      if (!this.canPredict(key)) {
        return {}
      }

      const growthRate = this.growthRate(key)

      const predict = durationDateList.reduce((acc, date, index, durationDateList) => {
        if (index < statsData.length || this.project[key][date]) {
          acc[date] = this.project[key][date]
          return acc
        }

        const yesterday = durationDateList[index - 1]

        acc[date] = Math.floor(acc[yesterday] * growthRate)

        return acc
      }, {})

      return predict
    },

    /**
     * 顯示畫面上的成長率
     * @param {String} key - stats 內要計算的 key name
     * @returns {String|Number} 成長率或是無法預測
     */
    predictDisplay(key) {
      if (this.canPredict(key)) {
        return this.growthRate(key).toFixed(5)
      }

      return '資料不足無法預測'
    },

    /**
     * 比前一天成長了多少
     * @param {Number} oldNumber - 被比較的數字
     * @param {Number} newNumber - 現在的數字
     * @returns {Number} 整數或是浮點數
     */
    getPercentageChange(oldNumber, newNumber) {
      return newNumber / oldNumber
    }
  }
}
